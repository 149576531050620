
<template>
  <main>
    <header
      class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10"
    >
      <div class="container-fluid">
        <div class="page-header-content pt-4">
          <div class="row align-items-center justify-content-between">
            <div class="col-auto mt-4">
              <h1 class="page-header-title">
                <div class="page-header-icon">
                  <i class="fas fa-info-circle"></i>
                </div>
                Listado de usuarios
              </h1>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="container-fluid mt-n10">
      <div class="card">
        <div class="card h-100">
          <div class="card-body">
            <div id="grupos" class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <span class="float-left">Listado</span>
                    <nuevoUsuarioComponent></nuevoUsuarioComponent>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-between">
                        <div class="d-flex justify-content-between">
                          <input
                            class="form-control form-control-sm form-control-solid mb-2"
                            v-model="pagination.search"
                            type="text"
                            aria-label="Search"
                          />
                          <button
                            class="btn btn-sm btn-info shadow-sm ml-2 mb-2"
                            title="Buscar"
                            @click="listar()"
                          >
                            <i class="fas fa-search text-white-50"></i>
                          </button>
                        </div>
                        <div class="d-flex justify-content-between">
                          <label class="mb-2 mr-2 d-block" for="resultPerPage">Mostrar</label>
                          <select
                            name="resultPerPage"
                            id="resultPerPage"
                            v-model="pagination.per_page"
                            class="form-control form-control-sm mb-2 mr-2 form-control-solid w-25"
                          >
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          <label class="mb-2 mr-2 d-block" for="resultPerPage"
                            >Por Página</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="table-responsive" ref="usuarios_wrapper">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Documento</th>
                              <th>Tipo</th>
                              <th>Nombre</th>
                              <th>Genero</th>
                              <th>Fecha/Nacimiento</th>
                              <th>Telefono</th>
                              <th colspan="2"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(usuarios, index) in usuariosEvt"
                              :key="index"
                            >
                              <td>{{ usuarios.id }}</td>
                              <td>{{ usuarios.documento }}</td>
                              <td>{{ (usuarios.tipo_documento || {}).codigo }}</td>
                              <td>
                                {{ usuarios.nombre_1 }} {{ usuarios.nombre_2 }}
                                {{ usuarios.apellido_1 }}
                              </td>
                              <td>{{ usuarios.sexo }}</td>
                              <td>{{ usuarios.fecha_nacimiento }}</td>
                              <td>{{ usuarios.telefono }}</td>
                              <td width="10px">
                               <router-link
                                class="btn btn-transparent"
                                :to=" { name: 'editarUsuarioEvt', params: { id: usuarios.id }}" title="Editar">
                                <i class="fas fa-user-edit"></i>
                                </router-link>
                              </td>
                              <td width="10px">
                                <router-link class="btn btn-transparent" :to="'/verUsuario/' + usuarios.id" >
                                  <i class="far fa-eye"></i>
                                </router-link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <nav aria-label="Page navigation example">
                          <ul class="pagination pagination-sm">
                            <li class="page-item">
                              <a
                                class="page-link"
                                @click.prevent="prevPage()"
                                aria-label="Previous"
                              >
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                              </a>
                            </li>
                            <li
                              v-for="(page, index) in pages"
                              class="page-item"
                              :class="{
                                active: pagination.current_page === page,
                              }"
                              :key="index"
                            >
                              <a
                                class="page-link"
                                style="cursor: pointer"
                                @click="changePage(page)"
                                >{{ page }}</a
                              >
                            </li>
                            <li class="page-item">
                              <a
                                class="page-link"
                                href="#"
                                @click.prevent="nextPage()"
                                aria-label="Next"
                              >
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Axios from "axios";
import nuevoUsuarioComponent from "../UsuariosEvt/NuevoUsuarioEvt";
import {isEmpty, debounce} from 'lodash';

export default {
  components: {
    nuevoUsuarioComponent
  },
  created() {
    this.listar();
    this.debounceListar = debounce(this.listar, 300);
  },
  data() {
    return {

      usuariosEvt: [],
      pagination: {
        data: [],
        per_page: 15,
        page: 1,
        current_page: 1,
        search: "",
        total: "",
      },
    };
  },
  watch: {
    search: function () {
      this.debounceListar();
    },
  },
  methods: {
    async listar(page = undefined) {

      if (page !== undefined) {
        this.pagination.page = page;
      }

      let url = `/api/usuarios-evt/listar?`;

      if(this.pagination.search !== ''){
          url += `s=${this.pagination.search}`;
      }

      url += `&per_page=${this.pagination.per_page}&page=${this.pagination.page}`;

      this.LoaderSpinnerShow();

      let response = await Axios.get(url);

      this.usuariosEvt = response.data.data;

      this.pagination.data = response.data.data;
      this.pagination.current_page = response.data.current_page;
      this.pagination.total = response.data.total;
      this.LoaderSpinnerHide();
    },

    changePage(page) {
      this.pagination.page = page;
      this.listar();
    },
    nextPage() {
      let next_page =
        Number(this.pagination.current_page) + 1 > this.pagination.total
          ? Number(this.pagination.current_page)
          : Number(this.pagination.current_page) + 1;
      this.pagination.page = next_page;
      this.listar();
    },
    prevPage() {
      let prev_page =
        Number(this.pagination.current_page) - 1 <= 0
          ? Number(this.pagination.current_page)
          : Number(this.pagination.current_page) - 1;

      this.pagination.page = prev_page;
      this.listar();
    },
  },
  computed: {
    tipoDocumentoUsuario: function () {
      if (isEmpty(this.usuario.tipo_documento)) {
        return "sin registrar";
      }

      return this.usuario.tipo_documento;
    },
    total_pages: function () {
      return !isEmpty(this.pagination.data)
        ? Math.ceil(this.pagination.total / this.pagination.per_page)
        : 0;
    },
    total() {
      return this.usuariosEvt.reduce(function (total, current) {
        return parseFloat(total) + parseFloat(current.separar);
      }, 0);
    },
    pages() {
      return Array(this.total_pages)
        .fill(1)
        .map((value, index) => Number(value) + index)
        .filter(
          (value) =>
            value >= this.pagination.current_page - 3 &&
            value <= Number(this.pagination.current_page) + 3
        );
    },
  },
};
</script>
})
