<template>
  <main>
    <button
      class="btn btn-primary btn-sm float-right"
      data-toggle="modal"
      data-target="#nuevo_usuario"
    >
      Nuevo Usuario
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="nuevo_usuario"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-scrollable modal-lg"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="nuevo_usuario">Crear usuario</h5>
            <button
              class="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <h5 class="card-title text-muted">
                <i class="fas fa-user-shield"></i>Datos personales
              </h5>
              <div class="form-row">
                <div class="form-group col-md-6 input-group-sm">
                  <label class="small mb-1 text-muted" for="inputFirstName"
                    >Primer nombre</label
                  >
                  <input
                    class="form-control"
                    type="text"
                    font-size="responsive"
                    :class="{ 'is-invalid': $v.usuarios.nombre_1.$error }"
                    v-model="usuarios.nombre_1"
                    required
                  />
                </div>
                <div class="form-group col-md-6 input-group-sm">
                  <label class="small mb-1 text-muted" for="inputLastName"
                    >Segundo nombre
                  </label>
                  <input
                    class="form-control"
                    type="text"
                    font-size="responsive"
                    v-model="usuarios.nombre_2"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6 input-group-sm">
                  <label class="small mb-1 text-muted" for="inputFirstName"
                    >Primer apellido</label
                  >
                  <input
                    class="form-control"
                    id="inputFirstName"
                    type="text"
                    font-size="responsive"
                    :class="{ 'is-invalid': $v.usuarios.apellido_1.$error }"
                    v-model="usuarios.apellido_1"
                    required
                  />
                </div>
                <div class="form-group col-md-6 input-group-sm">
                  <label class="small mb-1 text-muted" for="inputLastName"
                    >Segundo apellido
                  </label>
                  <input
                    class="form-control"
                    type="text"
                    font-size="responsive"
                    v-model="usuarios.apellido_2"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-3 input-group-sm">
                  <label class="small mb-1 text-muted" for="inputFirstName"
                    >Genero</label
                  >
                  <div
                    class="input-group mb-2 mr-2 input-group-joined input-group-sm"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text"> </span>
                    </div>
                    <select
                      class="form-control"
                      placeholder=""
                      font-size="responsive"
                      :class="{ 'is-invalid': $v.usuarios.genero.$error }"
                      v-model="usuarios.genero"
                      required
                    >
                      <option
                        v-for="(genero, index) in generos"
                        :value="genero.nombre_guardado"
                        v-bind:key="index"
                      >
                        {{ genero.nombre }}-{{ genero.nombre_guardado }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group col-md-3 input-group-sm">
                  <label class="small mb-1 text-muted" for="inputLastName">
                    Documento
                  </label>
                  <input
                    class="form-control"
                    type="text"
                    font-size="responsive"
                    :class="{ 'is-invalid': $v.usuarios.documento.$error }"
                    v-model="usuarios.documento"
                    required
                  />
                  <span
                    class="text-danger"
                    v-if="
                      !$v.usuarios.documento.isUnique &&
                      $v.usuarios.documento.$dirty
                    "
                    >El numero de documento ya se encuentra registrado</span
                  >
                </div>
                <div class="form-group col-md-3 input-group-sm">
                  <label class="small mb-1 text-muted">Tipo documento </label>
                  <div
                    class="input-group mb-2 mr-2 input-group-joined input-group-sm"
                    font-size="responsive"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text"></span>
                    </div>
                    <select
                      class="form-control"
                      placeholder=""
                      aria-label="tercero"
                      :class="{
                        'is-invalid': $v.usuarios.tipo_documento.$error,
                      }"
                      font-size="responsive"
                      v-model="usuarios.tipo_documento"
                      required
                    >
                      <option
                        v-for="(tipoDocumento, index) in tipoDocumentos"
                        font-size="responsive"
                        v-bind:key="index"
                        :value="tipoDocumento.id"
                      >
                        {{ tipoDocumento.nombre }}
                      </option>
                    </select>
                  </div>
                  <!-- <span class="invalid-feedback " v-if="!$v.usuarios.tipo_documento.required">  este campo es requerido</span> -->
                </div>
                <div class="form-group col-md-3 input-group-sm">
                  <label class="small mb-1 text-muted" for="inputLastName"
                    >Fecha de nacimiento
                  </label>
                  <input
                    class="form-control"
                    type="date"
                    font-size="responsive"
                    :class="{
                      'is-invalid': $v.usuarios.fecha_nacimiento.$error,
                    }"
                    v-model="usuarios.fecha_nacimiento"
                    required
                  />
                </div>
              </div>
            </form>
            <h5 class="card-title text-muted">
              <i class="fas fa-check-double"></i>Informacion general
            </h5>

            <form>
              <div class="form-row">
                <div class="form-group col-md-6 input-group-sm">
                  <label class="small mb-1 text-muted" for="inputFirstName"
                    >ocupacion</label
                  >
                  <VSelectProfesion
                    v-model="usuarios.id_profesion"
                    mode="object"
                  >
                  </VSelectProfesion>
                </div>
                <div class="form-group col-md-6 input-group-sm">
                  <label class="small mb-1 text-muted" for="inputLastName"
                    >Estado civil
                  </label>
                  <vSelectEstadoCivil
                    v-model="usuarios.estado_civil"
                    mode="object"
                  >
                  </vSelectEstadoCivil>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6 input-group-sm">
                  <label
                    class="small mb-1 text-muted"
                    for="inputLastName"
                    teext_color="black"
                    >Etnia
                  </label>
                  <vSelectEtnia v-model="usuarios.etnia" mode="object">
                  </vSelectEtnia>
                </div>
                <div class="form-group col-md-6 input-group-sm">
                  <label class="small mb-1 text-muted" for="inputLastName"
                    >Telefono
                  </label>
                  <input
                    class="form-control"
                    type="text"
                    font-size="responsive"
                    :class="{ 'is-invalid': $v.usuarios.telefono.$error }"
                    v-model="usuarios.telefono"
                    required
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6 input-group-sm">
                  <label class="small mb-1 text-muted" for="inputLastName"
                    >Eps
                  </label>
                  <v-select-eps
                    v-model="usuarios.eps"
                    mode="object"
                    :class="{ 'v-select-error': $v.usuarios.eps.$error }"
                  >
                  </v-select-eps>
                </div>
                <div class="form-group col-md-6 input-group-sm">
                  <label class="small mb-1 text-muted" for="nivel">Nivel</label>
                  <select
                    class="form-control"
                    id="nivel"
                    v-model="usuarios.nivel"
                  >
                    <option value="1" selected>Nivel 1</option>
                    <option value="2">Nivel 2</option>
                    <option value="3">Nivel 3</option>
                  </select>
                </div>
              </div>
            </form>
            <h5 class="card-title text-muted">
              <i class="fas fa-street-view"></i>Ubicacion del usuario
            </h5>
            <div class="form-row">
              <div class="form-group col-md-12 input-group-sm">
                <label class="small mb-1 text-muted" for="inputLastName"
                  >Municipio
                </label>
                <vSelectMunicipio
                  v-model="usuarios.municipio"
                  mode="object"
                  :class="{ 'v-select-error': $v.usuarios.municipio.$error }"
                >
                </vSelectMunicipio>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6 input-group-sm">
                <label
                  class="small mb-1 text-muted"
                  for="inputLastName"
                  teext_color="black"
                  >Direccion
                </label>
                <input
                  class="form-control"
                  id="inputLastName"
                  type="text"
                  font-size="responsive"
                  :class="{ 'is-invalid': $v.usuarios.direccion.$error }"
                  v-model="usuarios.direccion"
                />
              </div>
              <div class="form-group col-md-6 input-group-sm">
                <label
                  class="small mb-1 text-muted"
                  for="inputLastName"
                  teext_color="black"
                  >Zona</label
                >

                <vSelectZona
                  v-model="usuarios.zona"
                  mode="object"
                ></vSelectZona>
              </div>
            </div>
            <form></form>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            <button class="btn btn-primary" type="button" @click="Guardar()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Axios from "axios";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2/src/sweetalert2.js";
import VSelectProfesion from "../../components/common/VSelectProfesion";
import vSelectZona from "../../components/common/vSelectZona";
import vSelectEtnia from "../../components/common/vSelectEtnia";
import vSelectEstadoCivil from "../../components/common/vSelectEstadoCivil";
import vSelectMunicipio from "../../components/common/vSelectMunicipio";
import VSelectEps from "../../components/common/VSelectEps";
import {isEmpty} from 'lodash';
import $ from 'jquery';

export default {
  components: {
    VSelectProfesion,
    vSelectZona,
    vSelectEtnia,
    vSelectEstadoCivil,
    VSelectEps,
    vSelectMunicipio,
  },
  created() {
    this.listarTipoDocumento();
    this.listarGeneros();
  },
  data() {
    return {
      tipoDocumentos: [],
      generos: [],
      usuarios: {
        nombre_1: "",
        nombre_2: "",
        apellido_1: "",
        apellido_2: "",
        genero: "",
        documento: "",
        tipo_documento: "",
        fecha_nacimiento: "",
        id_profesion: {},
        estado_civil: "",
        etnia: {},
        zona: {},
        direccion: "",
        municipio: {},
        telefono: "",
        eps: {},
        nivel: "",
      },
    };
  },
  filters: {
    upper: function (value) {
      return value.toUpperCase();
    },
  },
  validations: {
    usuarios: {
      nombre_1: { required },
      apellido_1: { required },
      genero: { required },
      documento: {
        required,
        async isUnique(value) {
          if (value === "") {
            return true;
          }

          const response = await Axios.get(
            `/api/usuarios-evt/usuario-duplicado/${value}`
          );

          return Boolean(response.data);
        },
      },
      tipo_documento: { required },
      fecha_nacimiento: { required },
      direccion: { required },
      municipio: { required },
      telefono: { required },
      eps: { required },
    },
  },
  methods: {
    limpiar() {
      this.usuarios.nombre_1 = "";
      this.usuarios.nombre_2 = "";
      this.usuarios.apellido_1 = "";
      this.usuarios.apellido_2 = "";
      this.usuarios.genero = "";
      this.usuarios.documento = "";
      this.usuarios.tipo_documento = "";
      this.usuarios.fecha_nacimiento = "";
      this.usuarios.estado_civil = "";
      this.usuarios.direccion = "";
      this.usuarios.nivel = "";
      this.usuarios.telefono = "";
      this.usuarios.id_profesion = {};
      this.usuarios.etnia = {};
      this.usuarios.zona = {};
      this.usuarios.municipio = {};
      this.usuarios.eps = {};
    },
    listarTipoDocumento() {
      var url = "/api/tipoDocumento/listar";
      Axios.get(url).then((response) => {
        this.tipoDocumentos = response.data;
      });
    },
    listarGeneros() {
      var url = "/api/genero/listar";
      Axios.get(url).then((response) => {
        this.generos = response.data;
      });
    },

    Guardar() {
      this.$v.usuarios.$touch();

      if (this.$v.usuarios.$invalid) {
        return;
      }

      const tipoDocSelected = this.tipoDocumentos.find(
        (x) => x.id === this.usuarios.tipo_documento
      );

      console.log(tipoDocSelected);

      if (
        !(
          this.edad >= tipoDocSelected.edad_inicio &&
          this.edad <= tipoDocSelected.edad_final
        )
      ) {
        Swal.fire(
          `El tipo documento seleccionado no cumple con el rango ${tipoDocSelected.edad_inicio} hasta ${tipoDocSelected.edad_final} años`,
          "",
          "error"
        );
        return;
      }

      var url = "/api/usuarios-evt/guardar";

      Axios.post(url, this.usuarios).then(() => {
        Swal.fire({
          title: "base de datos ",
          text: " guardado con exito",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        this.limpiar();
        $("#nuevo_usuario").modal("hide");
      });
    },
  },
  computed: {
    fechaActual: function () {
      const today = moment();
      return today.format("YYYY/MM/DD");
    },
    edad: function () {
      if (isEmpty(this.usuarios.fecha_nacimiento)) {
        return "";
      }

      return moment()
        .diff(moment(this.usuarios.fecha_nacimiento, "YYYY-MM-DD"), "year")
        .toString();
    },
  },
};
</script>
